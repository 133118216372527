import { Navigate, useParams } from '@remix-run/react';
import { useTitle } from 'react-use';
import { $path } from 'remix-routes';

import { EventManage } from '../../components/Event/Manager';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { makeTitle } from '../../utils/common';

// eslint-disable-next-line import/no-default-export
export default function MyEventManage(): JSX.Element | null {
  useTitle(makeTitle('Update Scheduled Event'));
  const { eventId } = useParams<'eventId'>();

  const newSchedulerEnabled = useFeatureQueryParam('new-event-scheduler');

  if (newSchedulerEnabled) {
    return (
      <Navigate
        to={$path(
          '/events/:id/schedule',
          {
            id: eventId ?? '',
          },
          window.location.search
        )}
        replace
      />
    );
  }
  return (
    <PublicHomeLayout>
      <EventManage withAdminHeader={true} home='/events' />
    </PublicHomeLayout>
  );
}
